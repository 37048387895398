@import 'variables';
@import '~primeng/resources/components/dialog/dialog.css';

.p-dialog-mask {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  background-color: $ds-color-black-trans;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition-property: background-color;
  width: 100%;
}

.p-dialog {
  border-radius: 3px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
  pointer-events: auto;
  width: 50vw;

  @media screen and (max-width: 40em) {
    width: 80vw;
  }

  .p-dialog-header {
    background: $ds-color-white;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom: 1px solid $ds-color-medium-light-grey;
    color: $ds-color-n500;
    padding: 18px 30px 15px;
  }

  .p-dialog-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
  }

  .p-dialog-content {
    background: $ds-color-white;
    color: $ds-color-medium-dark-grey;
    overflow-y: auto;
    padding: 5px 15px;
    @media (min-width: 768px) {
      padding: 5px 30px;
    }
  }

  .p-dialog-footer {
    border-top: 0 none;
    background: $ds-color-white;
    color: $ds-color-medium-dark-grey;
    text-align: right;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top: 1px solid $ds-color-medium-light-grey;
    padding: 9px 5px;
  }
}
