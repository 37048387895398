/* You can add global styles to this file, and also import other style files */

@import '../../../libs/orderform-widgets/src/assets/global';

div.unzerSandboxNotify {
  display: none !important;
}

/**
 * Do not set margin for maybe empty widgets
 * because there is no content
 */
ds-orderform-currency-selector,
ds-orderform-input-additional,
ds-orderform-input-checkboxes,
ds-orderform-input-voucher,
ds-orderform-language-selector,
ds-orderform-payment-plans {
  &:empty {
    margin-top: 0 !important;
  }
}

/**
 * Disable interaction on Inputs of PGB is in editor mod
 */
body.scene {
  &.design-mode {
    // disable Payment-Methods only on design-mode
    ds-orderform-payment-methods {
      pointer-events: none;
    }
  }

  /**
   * disable global on any mode in editor
   */
  .DIE, // Disable elements (manual selection)
  ds-orderform-guarantee-seal,
  ds-orderform-currency-selector,
  ds-orderform-input-additional,
  ds-orderform-input-address,
  ds-orderform-input-checkboxes,
  ds-orderform-input-voucher,
  ds-orderform-language-selector,
  ds-orderform-legal-notice,
  ds-orderform-order-bump,
  ds-orderform-order-summary,
  ds-orderform-payment-plans,
  ds-orderform-product-list,
  ds-orderform-footer,
  ds-orderform-product-quantity-discount {
    input,
    select,
    textarea,
    button,
    a,
    label,
    p-dropdown {
      // Global disable for Angular - Inputs
      pointer-events: none;
    }
  }

  ds-orderform-guarantee-seal,
  ds-orderform-currency-selector,
  ds-orderform-input-additional,
  ds-orderform-input-address,
  ds-orderform-input-checkboxes,
  ds-orderform-input-voucher,
  ds-orderform-language-selector,
  ds-orderform-legal-notice,
  ds-orderform-order-summary,
  ds-orderform-payment-methods,
  ds-orderform-payment-plans,
  ds-orderform-product-list {
    &:empty {
      margin: 1em 0;
      padding: 0.5em;
      background-color: #409dff1a;
      display: block;
      &:before {
        content: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cstyle%3E.spinner_S1WN%7Banimation%3Aspinner_MGfb%20.8s%20linear%20infinite%3Banimation-delay%3A-.8s%7D.spinner_Km9P%7Banimation-delay%3A-.65s%7D.spinner_JApP%7Banimation-delay%3A-.5s%7D%40keyframes%20spinner_MGfb%7B93.75%25%2C100%25%7Bopacity%3A.2%7D%7D%3C%2Fstyle%3E%3Ccircle%20class%3D%22spinner_S1WN%22%20cx%3D%224%22%20cy%3D%2212%22%20r%3D%223%22%2F%3E%3Ccircle%20class%3D%22spinner_S1WN%20spinner_Km9P%22%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%223%22%2F%3E%3Ccircle%20class%3D%22spinner_S1WN%20spinner_JApP%22%20cx%3D%2220%22%20cy%3D%2212%22%20r%3D%223%22%2F%3E%3C%2Fsvg%3E');
      }
    }
  }
}
