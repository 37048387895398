ds-orderform-guarantee-seal:empty {
  display: block;
  min-height: 150px;
}

ds-orderform-product-list:empty {
  display: block;
  min-height: 285px;
  @media (min-width: 1024px) {
    min-height: 150px;
  }
}

ds-orderform-order-summary:empty {
  display: block;
  min-height: 120px;
}

ds-orderform-payment-methods:empty {
  display: block;
  min-height: 238px;
}

ds-orderform-input-address:empty {
  display: block;
  min-height: 180px;
}

ds-orderform-legal-notice:empty {
  display: block;
  min-height: 100px;
}
