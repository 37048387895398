@import 'variables';
@import '~primeng/resources/components/dropdown/dropdown.css';

/**
  * Dropdown options
  */
p-dropdown {
  display: flex;

  &.disabled {
    background: $ds-color-light-grey;
  }

  .p-dropdown {
    width: 100%;

    .p-dropdown-panel {
      //see @import '~primeng/resources/components/dropdown/dropdown.css';
      right: -18px;

      //Small Styling
      background: $ds-color-white;
      box-shadow: 0 2px 6px rgba(49, 55, 60, 0.15),
        0 6px 10px rgba(49, 55, 60, 0.3);
      border-radius: 2px;
      margin: 10px (($formControlHorizontalPadding * 2 + 6) * -1) 0
        ($formControlHorizontalPadding * -1);
    }

    .p-dropdown-trigger-icon {
      color: $ds-color-grey;
      margin-top: 3px; // pixel schubsen
    }

    .p-dropdown-items {
      padding: 8px 0;
    }

    .p-dropdown-item {
      padding: 8px 12px;

      &.p-highlight {
        background-color: $ds-color-very-light-grey;
        display: none; // hide selected DS-7921
      }

      &:hover {
        background-color: $ds-color-light-grey;
      }
    }

    .p-dropdown-header {
      .p-dropdown-filter-container {
        .p-dropdown-filter-icon {
          top: 0;
          margin-top: 0.8125rem;
          left: 0.625rem;
          background-color: $ds-color-grey;
          mask-repeat: no-repeat;
          mask-position: center;
          mask-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.09718 9.6146C1.40373 8.80735 0.327489 7.09608 0.333414 5.22008C0.32473 3.07745 1.7184 1.18121 3.7659 0.549796C5.81341 -0.0816132 8.03299 0.700374 9.23254 2.47577C10.4321 4.25116 10.3294 6.60222 8.97957 8.26623L13.5232 12.7893L12.7847 13.5278L8.25136 8.99444C6.79773 10.1803 4.79063 10.4218 3.09718 9.6146ZM5.20521 9.06623C7.32938 9.06623 9.05137 7.34425 9.05137 5.22008C9.0541 4.19918 8.64976 3.2193 7.92787 2.49742C7.20598 1.77553 6.22611 1.37119 5.20521 1.37392C3.08104 1.37392 1.35906 3.0959 1.35906 5.22008C1.35906 7.34425 3.08104 9.06623 5.20521 9.06623Z' /%3E%3C/svg%3E%0A");

          &:before {
            visibility: hidden;
          }
        }

        .p-dropdown-filter {
          border: 0;
          border-bottom: 1px solid $ds-color-grey;
          padding: 10px 12px;
          padding-left: 32px;
          background-color: #fff;
          outline: none;
        }
      }
    }
  }
}
