@import 'variables';
@import 'primeicons/primeicons.css';
//@import 'primeng/resources/primeng.css';
// Need as basic css helpers for components (6,4kb)
@import 'primeng/resources/components/common/common.css';
@import 'primeng/resources/components/calendar/calendar.css';
@import 'primeng/resources/components/tooltip/tooltip.css';
@import 'primeng/resources/components/dialog/dialog.css';
@import 'primeng-dialog';
@import 'primeng-dropdown';

.p-tooltip {
  max-width: 300px;
  padding: 0 !important;
  background-color: $ds-color-white-trans;
  box-shadow: 0 0 1px black;
}

.p-tooltip-text,
.ds-info {
  $iconSize: 15px;
  $spaceH: 8px;
  $spaceV: 6px;
  position: relative;
  background: $ds-color-very-light-grey;
  border-radius: 2px;
  padding: $spaceV $spaceH;
  padding-left: $iconSize + $spaceH + $spaceV;

  &:before {
    position: absolute;
    top: $spaceH;
    left: $spaceH;
    content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.00003 0.333332C3.31813 0.333332 0.333359 3.3181 0.333359 7C0.333359 10.6819 3.31813 13.6667 7.00003 13.6667C10.6819 13.6667 13.6667 10.6819 13.6667 7C13.6667 5.23189 12.9643 3.5362 11.7141 2.28595C10.4638 1.03571 8.76814 0.333332 7.00003 0.333332ZM6.32267 3.67753V5.03915H7.82054V3.67753H6.32267ZM6.34337 10.3658V5.66667H7.75815V10.3658H6.34337Z' fill='%233988E3'/%3E%3C/svg%3E%0A");
    width: $iconSize;
    height: $iconSize;
    display: inline-block;
    vertical-align: middle;
  }
}

.p-overlaypanel {
  margin: 10px 0 0 0;
  position: absolute;
  background-color: $ds-color-medium-blue;
  color: $ds-color-white;
  padding: 5px;
  border-radius: 2px;

  ul {
    margin: 0;
    padding-left: 20px;

    strong {
      white-space: nowrap;
    }
  }

  td {
    padding: 5px 3px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &:before {
    box-sizing: border-box;
    bottom: 100%;
    left: 1.25em;
    border: solid rgba(200, 200, 200, 0) 10px;
    content: ' ';
    height: 20px;
    width: 20px;
    position: absolute;
    pointer-events: none;
    margin-right: -10px;
    border-bottom-color: $ds-color-medium-blue;
  }

  .p-overlaypanel-content {
    padding: 0.571em 1em;
    text-align: left;
  }
}

/**
 * Own prime ng theme
 */

span.p-calendar {
  display: block;

  .p-inputtext {
    width: 100%;
  }
}

.p-component {
  // Datepicker style
  // https://www.figma.com/file/izOA9tYdjoLKdViK3CaXYb/UXD-2014-Improved-UI?node-id=937%3A5984
  + .p-datepicker {
    $padding: 6px 0px;
    $calendarOpacity: 0.5;

    margin: 10px 0;
    background: $ds-color-white;
    box-shadow: 0 4px 8px 0 $ds-color-grey-trans;
    border-radius: 2px;

    width: 350px;
    min-width: auto;
    padding-bottom: 10px;

    table {
      tr {
        padding: 0 !important;

        th {
          text-align: center;
          border-bottom: 1px solid $ds-color-light-grey;
          padding: $padding;
          font-weight: normal;

          > * {
            opacity: $calendarOpacity;
          }
        }

        // today btn
        td.p-datepicker-today span {
          border: 1px solid $ds-color-light-grey;
        }

        td {
          padding: $padding;

          // Calendar button..
          span {
            width: 2em;
            height: 2em;
            border-radius: 4em;

            &:hover {
              background-color: $ds-color-very-light-grey;
            }

            &.p-highlight {
              background: $ds-color-blue;
              color: $ds-color-white;
              border-color: transparent;
            }

            &.p-disabled {
              visibility: hidden;
            }
          }
        }
      }
    }

    .p-datepicker-header {
      padding: 15px;

      button {
        opacity: $calendarOpacity;
      }

      .p-datepicker-month {
        &:after {
          content: ' ';
        }
      }
    }

    .p-timepicker > * {
      margin: 0 4px;

      button {
        padding: 10px;
      }
    }

    a {
      cursor: pointer;
    }
  }
}
