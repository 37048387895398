@import 'variables';

.ds-button {
  /**
   * Global style for buttons
   */
  border-radius: 2px;
  outline: none;
  border: none;
  text-align: center;
  padding: 8px 32px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  background: transparent;
  color: $ds-color-blue;

  /**
   * Style without border
   * for Buttons without .primary | .secondary
   */
  &:hover,
  &:active,
  &:focus {
    color: $ds-color-blue-hover;
  }

  &:disabled {
    color: $ds-color-blue-text-disabled;
  }

  & + .ds-button {
    margin-left: 6px;
  }

  /**
   * Global Primary & Secondary settings
   */
  &.primary,
  &.secondary {
    box-shadow: 0px 2px 4px 0px rgba(49, 55, 60, 0.25);

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0px 4px 6px 0px rgba(49, 55, 60, 0.5);
    }
  }

  /**
   * Styles for .primary
   */
  &.primary {
    background: $ds-color-blue-hover;
    color: $ds-color-white;
    border: 1px solid $ds-color-blue-hover;

    &:hover,
    &:active,
    &:focus {
      background-color: $ds-color-blue-hover;
    }

    &:disabled {
      background-color: $ds-color-blue-disabled;
      border: 1px solid $ds-color-blue-disabled;
      color: $ds-color-blue-text-disabled;
      box-shadow: none;
    }
  }

  /**
   * Styles for .secondary
   */
  &.secondary {
    border: 1px solid $ds-color-medium-light-grey;
    background: $ds-color-white;

    &:disabled {
      background: $ds-color-gray-bg-disabled;
      border-color: $ds-color-medium-light-grey;
      color: $ds-color-medium-light-grey;
      box-shadow: none;
    }
  }
}

.ds-button-toggle-group {
  margin-bottom: 5px;

  .button {
    cursor: pointer;
    color: $ds-color-dark-grey;
    min-height: 40px;
    border: 0;
    margin-left: -1px;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    padding: 8px 10px;
    z-index: 0;
    position: relative;
    margin-bottom: 0;
    border-radius: 0;
    background-color: unset;
    display: inline-block;
    width: auto;
    text-transform: none;
    box-shadow: 0 0 0 1px $ds-color-light-grey inset;

    input {
      display: none;
    }

    &:first-child {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      margin-left: 0;
    }

    &:last-child {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }

    &.active {
      z-index: 1;
    }

    &.active,
    &:hover {
      .bg-container {
        background-color: $ds-color-blue;
      }
    }

    .bg-container {
      pointer-events: none;
      position: absolute;
      background-color: transparent;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
    }
  }
}
