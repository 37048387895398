.autofill-events {
  &:-webkit-autofill {
    animation-name: onAutoFillStart;
    animation-duration: 0.001s;
  }

  &:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
    animation-duration: 0.001s;
  }
}
@keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}
@keyframes onAutoFillCancel {
  from {
    /**/
  }
  to {
    /**/
  }
}
