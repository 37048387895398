@import 'variables';

/**
 * Checkbox styles
 * Radio styles
 * Used in multible Widgets
 */
input[type='radio'],
input[type='checkbox'] {
  $positiveColor: $ds-color-blue-checkbox;
  $neutralColor: $ds-color-medium-light-grey;
  $muteValue: 0.5;
  $checkElementHeight: 20px;
  $checkElementWidth: $checkElementHeight;
  $checkElementBorderWidth: 2px;
  display: none;
  pointer-events: none;

  /**
   * Basic design
   */
  + .input-radio,
  + .input-checkbox {
    position: relative;
    cursor: pointer;
    padding-left: 1.875em;
    padding-top: 0.1875em;
    display: block;
    min-height: $checkElementHeight + ($checkElementBorderWidth * 2);

    &:empty {
      padding-top: 0;
      padding-left: $checkElementWidth + ($checkElementBorderWidth * 2);
    }

    &:hover:before {
      border-color: $positiveColor;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      border: $checkElementBorderWidth solid $neutralColor;
      border-radius: 2px;
      width: $checkElementWidth;
      height: $checkElementHeight;
      display: inline-block;
      content: '';
      vertical-align: bottom;
      text-align: center;
    }
  }

  /**
   * Special Radio settings
   */
  + .input-radio {
    &:before {
      top: 0;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  /**
   * Invalid
   */
  &.ng-invalid.ng-touched {
    + .input-radio:before,
    + .input-checkbox:before {
      border-color: $ds-color-light-red;
    }
  }

  /**
   * Disabled || readonly
   */
  &[readonly='true'],
  &:disabled {
    + .input-radio,
    + .input-checkbox {
      opacity: $muteValue;

      &:before {
        border-color: $ds-color-medium-darker-grey;
      }
    }

    &:checked {
      + .input-radio,
      + .input-checkbox {
        opacity: 1; //do not mute label if checked...
        &:before {
          opacity: $muteValue; //mute only checkbox
        }
      }
    }
  }

  /**
  * Checked
  */
  &:checked {
    + .input-radio:before,
    + .input-checkbox:before {
      background: $positiveColor url('../icons/check.svg') center no-repeat;
      border-color: $positiveColor;
    }

    /**
     * Special Radio settings
     */
    + .input-radio:before {
      background: $positiveColor;
      box-shadow: 0 0 0 3px $ds-color-white inset;
    }

    //show neutral color if disabled...
    &[readonly='true'],
    &:disabled {
      + .input-checkbox:before {
        background-color: $neutralColor;
        border-color: $neutralColor;
      }
    }
  }
}
